export const MOBILE_NAV_OPEN = "MOBILE_NAV_OPEN";
export const MOBILE_NAV_CLOSE = "MOBILE_NAV_CLOSE";
export const MOBILE_PRODUCTS_FILTERS_OPEN = "MOBILE_PRODUCTS_FILTERS_OPEN";
export const MOBILE_PRODUCTS_FILTERS_CLOSE = "MOBILE_PRODUCTS_FILTERS_CLOSE";

export const FILTER_PRODUCTS = "FILTER_PRODUCTS";
export const SORT_PRODUCTS = "SORT_PRODUCTS";

export const UPDATE_PRODUCTS_SELECTED_SORT = "UPDATE_PRODUCTS_SELECTED_SORT";
export const UPDATE_PRODUCTS_SELECTED_FILTERS =
  "UPDATE_PRODUCTS_SELECTED_FILTERS";
export const CLEAR_PRODUCTS_SELECTED_FILTERS =
  "CLEAR_PRODUCTS_SELECTED_FILTERS";

export const SET_ALL_PRODUCTS = "SET_ALL_PRODUCTS";
export const SET_FILTERED_PRODUCTS = "SET_FILTERED_PRODUCTS";

export const SET_CHECKOUT = "SET_CHECKOUT";
export const SET_LOADING = "SET_LOADING";
export const SET_JUST_ADDED = "SET_JUST_ADDED";
